import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { HomepageNoticeConfig } from './types';

export function useHomepageNoticeClickTracking(homepageNotice: HomepageNoticeConfig): () => void {
	const track = useTracking();

	return useCallback(() => {
		void track('homepage_notice_click', {
			homepageNotice,
		});
	}, [track, homepageNotice]);
}
