import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { TotalUserCount, type TotalUserCountQuery } from './getTotalUserCount.graphql';

export type TotalUserCountResponse = { count: number; localizedCount: string };

export async function getTotalUserCount({
	gql: { fetch },
}: UtilityContext): Promise<TotalUserCountResponse | undefined> {
	const { data } = await fetch<TotalUserCountQuery>({
		query: TotalUserCount,
		rejectOnError: true,
	});
	if (!data?.getTotalUserCount) return undefined;
	return {
		count: data.getTotalUserCount.total,
		localizedCount: data.getTotalUserCount.totalLocalized,
	};
}
