import type { JSX } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { PageMessage } from '@change-corgi/design-system/components/alerts';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

function ErrorBanner(): JSX.Element {
	const { translate } = useI18n();
	const petitionNotFound = useQueryParamValue('petition_not_found') === 'true';
	return (
		<>
			{petitionNotFound && (
				<PageMessage role="alert" severity="warning" message={translate('fe.errors.petition.does_not_exist')}>
					<Translate value="fe.errors.petition.does_not_exist" />
				</PageMessage>
			)}
		</>
	);
}

export { ErrorBanner };
