import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { DISPLAYED_VICTORIES } from 'src/app/pages/homepage/shared/consts';

import {
	FeaturedVictories,
	type FeaturedVictoriesQuery,
	type FeaturedVictoriesQueryVariables,
} from './getFeaturedVictories.graphql';

export type FeaturedVictoryPetition = FeaturedVictoriesQuery['featuredVictories'][0];
export type FeaturedVictoryOrganization = NonNullable<FeaturedVictoryPetition['organization']>;
export type FeaturedVictoryUser = FeaturedVictoryPetition['user'];
export type FeaturedVictoryUserPhoto = NonNullable<FeaturedVictoryUser['photo']>;
export type FeaturedVictoryOrganizationPhoto = NonNullable<FeaturedVictoryOrganization['photo']>;
export type FeaturedVictoryPhotoSize = FeaturedVictoryUserPhoto['userSmall'];
export type FeaturedVictoryCountry = NonNullable<FeaturedVictoryUser['country']>;

export async function getFeaturedVictories({
	gql: { fetch },
}: UtilityContext): Promise<readonly FeaturedVictoryPetition[]> {
	const { data } = await fetch<FeaturedVictoriesQuery, FeaturedVictoriesQueryVariables>({
		query: FeaturedVictories,
		variables: {
			limit: DISPLAYED_VICTORIES,
		},
		rejectOnError: false,
	});
	return data?.featuredVictories || [];
}
