import type { JSX } from 'react';

import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import { withPrefetchedData } from 'src/shared/prefetch';

import type { HomepagePagePrefetchedDataProps } from 'src/app/pages/homepage/pageContext';
import { HomepagePageContextProvider, prefetchPageContext } from 'src/app/pages/homepage/pageContext';

import { Layout } from '../layout';

import { HomepageErrorHandler } from './ErrorHandler';

export const Homepage = withPrefetchedData(
	function Homepage({ prefetchedData }: HomepagePagePrefetchedDataProps): JSX.Element {
		return (
			<HomepagePageContextProvider
				prefetchedData={prefetchedData}
				errorHandler={HomepageErrorHandler}
				loadingFallback={<Loader size="m" my={256} mx="auto" />}
			>
				<Layout />
			</HomepagePageContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [Layout],

		prefetchData: async (context) => prefetchPageContext(context),
	},
);
