import { Box, Container } from '@change-corgi/design-system/layout';

import { withPrefetchedData } from 'src/shared/prefetch';

import { Banner } from '../components/Banner';
import { DiscoverFeed } from '../components/DiscoverFeed';
import { ErrorBanner } from '../components/ErrorBanner';
import { FeaturedTopics } from '../components/FeaturedTopics';
import { FeaturedVictoriesCarousel } from '../components/FeaturedVictoriesCarousel';
import { Head } from '../components/Head';
import { Intro } from '../components/Intro';

import { useTrackPageView } from './hooks/useTrackPageView';

export const Layout = withPrefetchedData(
	() => {
		useTrackPageView();
		return (
			<>
				<Head />
				<ErrorBanner />
				<Box
					sx={{
						backgroundImage: "url('https://static.change.org/home/illustration-world-map-2015-08-18.gif')",
						backgroundSize: ['contain', null, null, 1200],
						backgroundPosition: ['50% 25px', '50% 0', '50% -75px', '50% -130px'],
						backgroundRepeat: 'no-repeat',
					}}
					data-qa="home-contents"
				>
					<Banner />
					<Container px={8} mb={0} data-qa="home-body">
						<Intro />
						<Box sx={{ display: ['none', null, 'block'] }}>
							<FeaturedVictoriesCarousel />
						</Box>
						<Box
							sx={{
								display: ['flex', null, 'grid'],
								flexDirection: 'column-reverse',
								gridTemplateColumns: '3fr 1fr',
								mb: 24,
							}}
						>
							<DiscoverFeed />
							<FeaturedTopics />
						</Box>
					</Container>
				</Box>
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [Banner],
	},
);
