import { FCM_HOMEPAGE_NOTICE } from '@change-corgi/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm, prefetchFcm } = createFcmFunctions(
	{
		notice: FCM_HOMEPAGE_NOTICE,
	},
	{ hook: true, prefetch: true },
);
