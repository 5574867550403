import type { JSX } from 'react';

import { FCM_STARFISH_HOMEPAGE_V2 } from '@change-corgi/fcm/configs';

import { withPrefetchedData } from 'src/shared/prefetch';

import { Homepage as HomepageV2 } from 'src/app/pages/homepageV2';

import { Homepage } from './index';

type HomepageWrapperPrefetchedUserData = {
	prefetchedUserData: {
		newPage: boolean;
	};
};

export const HomepageWrapper = withPrefetchedData(
	function HomepageWrapper({ prefetchedUserData }: HomepageWrapperPrefetchedUserData): JSX.Element {
		if (prefetchedUserData?.newPage) {
			return <HomepageV2 />;
		}

		return <Homepage />;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: async (context) => {
			const userData = await context.getUserData();
			return userData?.newPage ? [HomepageV2] : [Homepage];
		},
		prefetchUserData: async (context) => {
			const { fcm: fcmClient, experiments: experimentClient } = context.utilityContext;

			const fcm = await fcmClient.get({
				homepageV2: FCM_STARFISH_HOMEPAGE_V2,
			});

			const { isEnabled, experimentName } = fcm.homepageV2;

			if (!isEnabled) {
				return { newPage: false };
			}

			const experiment = experimentClient.get(experimentName);
			await experiment.treat();
			return { newPage: experiment.variation === 'variant' };
		},
	},
);
