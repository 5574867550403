import type { JSX } from 'react';

import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconCampaign } from '@change-corgi/design-system/icons';
import { Box, Container } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useViewTracking } from 'src/app/pages/homepage/shared/hooks';

import type { HomepageNoticeConfig } from './hooks';
import { useHomepageNoticeClickTracking } from './hooks';

export function HomepageNotice({ homepageNotice }: { homepageNotice: HomepageNoticeConfig }): JSX.Element {
	const trackClick = useHomepageNoticeClickTracking(homepageNotice);

	useViewTracking({ eventName: 'homepage_notice_view', properties: homepageNotice });

	return (
		// TODO redesign using new DS colors
		<Box sx={{ backgroundColor: '#fff9df' }} data-testid="dash-prompt-banner">
			<Container p={8}>
				<Box sx={{ display: ['block', 'none'] }} data-testid="homepage-notice-mobile">
					<Icon icon={iconCampaign} color="primary-changeRed" mx={4} sx={{ verticalAlign: 'middle' }} />
					{homepageNotice.mobile_prefix && (
						<Text size="small" fontWeight="bold" mx={4} sx={{ textTransform: 'uppercase' }}>
							{homepageNotice.mobile_prefix}
						</Text>
					)}
					<Text mx={4}>{homepageNotice.mobile_copy}</Text>
					<Link to={homepageNotice.url} mx={4} data-testid="homepage-notice-mobile-link" onClick={trackClick}>
						<Text>{homepageNotice.mobile_link_text}</Text>
					</Link>
				</Box>
				<Box sx={{ display: ['none', 'block'] }} data-testid="homepage-notice-desktop">
					<Icon icon={iconCampaign} color="primary-changeRed" mx={4} sx={{ verticalAlign: 'middle' }} />
					{homepageNotice.desktop_prefix && (
						<Text size="small" fontWeight="bold" mx={4} sx={{ textTransform: 'uppercase' }}>
							{homepageNotice.desktop_prefix}
						</Text>
					)}
					<Text mx={4}>{homepageNotice.desktop_copy}</Text>
					<Link to={homepageNotice.url} mx={4} data-testid="homepage-notice-desktop-link" onClick={trackClick}>
						<Text>{homepageNotice.desktop_link_text}</Text>
					</Link>
				</Box>
			</Container>
		</Box>
	);
}
